@font-face {
  font-family:'Hurricane-Regular';
  src: url("./resources/Hurricane-Regular.ttf");
}

@font-face {
  font-family:'AlexBrush-Regular';
  src: url("./resources/AlexBrush-Regular.ttf");
}

@font-face {
  font-family:'Tenor-Sans';
  src: url("./resources/TenorSans-Regular.ttf");
}

.App{
  background-color: #fdfaef;
}

div{
  font-family:'Tenor-Sans';
}

.header {
  display: flex;
  justify-content: space-between;
  position: absolute;
  color: white;
  z-index: 999;
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.link-container {
  display: flex;
  margin: auto;
  margin-right: 50px;
  width: 50%;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.monogram-container {
  margin: auto;
  width: 50%;
  margin-left: 0px;
} 

.monogram{
  margin-left: 20px;
  max-width: 200px;
  max-height: 200px;
  color: white;
  filter: invert(99%) sepia(0%) saturate(1355%) hue-rotate(164deg) brightness(100%) contrast(113%);
}

.navigation{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  color: #f2f1ee;
}

.main-content{
  position: relative;
  width: 100%;
  height: calc(100vw / 1.77);
  text-align: center;
}

.main-photo-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: cover;
}

.main-photo{
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: brightness(70%);
}

.title-container{
  position: relative;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f2f1ee;
  padding-bottom: 8%
}

.branch{
  max-width: 200px;
  max-height: 200px;
}

.title{
  font-family: 'AlexBrush-Regular';
  font-size: 5vw !important;
  font-weight: normal;
}

.secondary-title{
  font-family: 'AlexBrush-Regular';
  font-size: 2.5vw !important;
  font-weight: normal;
  color: #404951;
  margin: auto;
  margin-bottom: 30px;
}

#flipper {
  --fcc-digit-block-width: 3vw;
  --fcc-digit-block-height: 4.5vw;
  --fcc-digit-font-size: 2.25vw;
  --fcc-label-font-size: 0.75vw;
}

h3{
  font-size: 18px !important;
  font-weight: normal;
}

.wed-info {
  display: flex;
  flex-direction: column;
  font-size: 2vw;
  font-weight: bold;
  color: #f2f1ee;
}

.date{
  font-size: 1.5vw;
  font-weight: bold;
  color: #f2f1ee;
  margin: auto;
  margin-bottom: 10px;
}

.logo{
  width: 100vw;
  margin: auto;
  margin-left: 10px;
}

.countdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 80px;
}

.bride-comment-title {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
}

.bride-comment {
  text-align: justify;
  text-indent: 1.5em;
}

.cerimony-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 20px;
  width: 60%;
}

.gifts-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 20px;
  width: 100%;
}

.gifts-container{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.cerimony-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.dress-paragraph {
  text-align: justify;
}

.dress{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.dress-img{
  width: 40%;
  margin-top: 10px;
}

.confirm-presence {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  margin-bottom: 40px;
}

#GiftItem {
  width: 20vw; 
  margin: 10px !important;
}

#GiftImg {
  width: 100%;
  height: 70%;
}

.music-player-container {
  position: fixed;
  bottom: 0;
  font-size: 16px;
  right: 40px;
  background-color: #1c1c1c;
  color: #fff;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  max-width: 57px;
  padding: 9px 7px;
  text-align: center;
  white-space: nowrap;
  width: 57px;
}

#GiftBtn{
  background-color: #BD774A;
  border-radius: 100px;
  border: 0;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  width: 7vw;
  font-size: 0.8vw;
}

.form-submit-btn{
  background-color: #BD774A !important;
  color: white !important;
  border: black !important;
  font-family: "Lato", sans-serif;
}

.Xmark:hover{
  cursor: pointer;
}

.toast{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 998;
  color: white !important;
}

  @media all and (min-width: 0px) and (max-width: 610px) {
  .title{
    font-family: 'AlexBrush-Regular';
    font-size: 35px !important;
    font-weight: normal;
  }
  .monogram-container {
    margin: auto;
    width: 10%;
    margin-left: 0px;
  } 
  .monogram{
    margin: auto;
    max-width: 50px;
    max-height: 50px;
    color: white;
    filter: invert(99%) sepia(0%) saturate(1355%) hue-rotate(164deg) brightness(100%) contrast(113%);
  }
  .logo{
    width: 10vw;
    margin: auto;
  }
  .link-container {
    display: none;
  }
  .wed-info {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    font-weight: bold;
    color: #f2f1ee;
  }
  .bride-comment {
    font-size: 15px;
  }
  .secondary-title{
    font-family: 'AlexBrush-Regular';
    font-size: 6vw !important;
    font-weight: normal;
    color: #404951;
    margin: auto;
    margin-bottom: 30px;
  }
  .date{
    font-size: 10px;
    font-weight: bold;
    color: #f2f1ee;
    margin: auto;
    margin-bottom: 10px;
  }
  h2{
    font-family: 'AlexBrush-Regular';
    font-size: 25px !important;
    font-weight: normal;
    color: #404951;
    margin: auto;
    margin-bottom: 30px;
  }
  .countdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 30px;
  }
  #flipper {
    --fcc-digit-block-width: 6vw;
  --fcc-digit-block-height: 9vw;
  --fcc-digit-font-size: 4.5vw;
  --fcc-label-font-size: 1.5vw;
  }
  .cerimony-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 20px;
    width: 90%;
  }
  .map{
    width: 100%;
    max-height: 80%;
  }
  .cerimony-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }
  .dress-img{
    width: 90%;
    margin-top: 10px;
  }
  .gifts-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  }
  #GiftItem {
    width: 40vw !important; 
    margin: 2px !important;
  }
  #GiftBtn{
    width: 25vw;
    font-size: 3vw;
  }
}